import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Dialog } from './dialog';

export type DialogAlertProps = Omit<Dialog, 'showModalCTAs' | 'onCloseAndReset' | 'onDismiss'> & {
  /**
   * the text of the cancel button
   */
  cancel?: string | null;
  /**
   * the text of the confirm button
   */
  confirm?: string | null;
  /**
   * bolded heading text
   */
  heading?: string | null;
  /**
   * callback for when the cancel button is clicked.
   * If this is not provided, the cancel button will not show.
   */
  onCancel?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  /**
   * callback for when the confirm button is clicked.
   */
  onConfirm: NonNullable<React.DOMAttributes<HTMLButtonElement>['onClick']>;
  /**
   * whether or not to stack the buttons vertically
   */
  stackedButtons?: boolean;
};

/**
 * A dialog meant for a user to confirm an action. Escape will not close this dialog.
 */
const DialogAlert: React.FC<React.PropsWithChildren<DialogAlertProps>> = ({
  children,
  cancel,
  confirm,
  heading,
  onCancel,
  onConfirm,
  stackedButtons = true,
  ...rest
}) => {
  const { t } = useTranslation('osc-dialog');
  const cancelText = cancel ?? t('cancel');
  const confirmText = confirm ?? t('ok');
  return (
    <Dialog size="sm" {...rest}>
      <div className="text-center">
        {heading ? <h2 className="mb-3 text-xl font-bold leading-tight">{heading}</h2> : null}
        {children}
      </div>
      <div
        className={cx('flex items-center justify-center pt-4', {
          'space-x-2': !stackedButtons,
          'flex-col space-y-2': stackedButtons,
        })}
      >
        <button type="button" className="btn btn-lg btn-primary w-full" onClick={onConfirm}>
          {confirmText}
        </button>
        {onCancel ? (
          <button
            type="button"
            className="btn btn-lg btn-primary-outline w-full"
            onClick={onCancel}
          >
            {cancelText}
          </button>
        ) : null}
      </div>
    </Dialog>
  );
};

export { DialogAlert };
export default DialogAlert;
