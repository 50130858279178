import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';
import { useGetSmbMultiBusinessToggleQuery } from '../gql/queries';
import { CDN_STALE_TIME } from '@dx-ui/framework-react-query';

export function useSMBMultiBusinessToggle() {
  const { data, isLoading } = useGetSmbMultiBusinessToggleQuery(undefined, {
    staleTime: CDN_STALE_TIME,
  });

  return {
    isLoading,
    smbMultiBusinessEnabled: getIsFeatureToggleEnabled({
      featureToggleData: data,
      name: 'smbMultiBusiness',
    }),
  };
}
